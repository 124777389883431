<template>
  <q-layout view="lHh lpR fFf">
    <app_layout></app_layout>
    <q-page-container class="page_container">
      <div class="check">
        <div class="title">카테고리</div>
        <q-list class="check-list">
          <q-item tag="label" v-ripple class="item">
            <q-item-section avatar class="item-section">
              <q-checkbox
                v-model="category"
                val="스파이크프라임"
                color="teal"
                @update:model-value="targetChange"
              />
            </q-item-section>
            <q-item-section avatar class="item-section">
              <q-item-label>스파이크프라임</q-item-label>
            </q-item-section>
          </q-item>
          <q-item tag="label" v-ripple>
            <q-item-section avatar>
              <q-checkbox
                v-model="category"
                val="브릭큐에센셜"
                color="teal"
                @update:model-value="targetChange"
              />
            </q-item-section>
            <q-item-section avatar>
              <q-item-label>브릭큐에센셜</q-item-label>
            </q-item-section>
          </q-item>
          <q-item tag="label" v-ripple>
            <q-item-section avatar>
              <q-checkbox
                v-model="category"
                val="미설정"
                color="teal"
                @update:model-value="targetChange"
              />
            </q-item-section>
            <q-item-section avatar>
              <q-item-label>미설정</q-item-label>
            </q-item-section>
          </q-item>
          <q-item tag="label" v-ripple>
            <q-item-section avatar>
              <q-checkbox
                v-model="category"
                val="EV3"
                color="teal"
                @update:model-value="targetChange"
              />
            </q-item-section>
            <q-item-section avatar>
              <q-item-label>EV3</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
      <div class="desc">
        <!-- Your selection is: <strong>{{ category }}</strong> -->
      </div>
      <div class="tabs">
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="teal"
          indicator-color="teal"
          align="left"
          narrow-indicator
        >
          <q-tab name="all" label="전체" />
          <q-tab name="public" label="공개" />
          <q-tab name="private" label="비공개" />
        </q-tabs>
        <div class="recent-day">
          <q-item clickable v-ripple>
            <q-item-section avatar> 최근 날짜 </q-item-section>
            <q-item-section>
              <q-icon :name="'unfold_more'"></q-icon>
            </q-item-section>
          </q-item>
        </div>
      </div>
      <q-tab-panels v-model="tab" animated class="panles">
        <q-tab-panel name="all">
          <div class="row">
            <QCard v-for="item in changeinitData" :key="item.name" :cardInfo="item"></QCard>
          </div>
        </q-tab-panel>

        <q-tab-panel name="public">
          <div class="row">
            <QCard v-for="item in changepublicData" :key="item.name" :cardInfo="item"></QCard>
          </div>
        </q-tab-panel>

        <q-tab-panel name="private">
          <div class="row">
            <QCard v-for="item in changeprivateData" :key="item.name" :cardInfo="item"></QCard>
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </q-page-container>
  </q-layout>
</template>

<style>
.page_contents {
  display: flex;
  align-items: center;
  justify-content: center;
}
.check {
  background: white;
  /* border: 1px solid gray; */
  display: flex;
}
.check-list {
  border: 1px solid gray;
  border-radius: 15px;
  padding-right: 50%;
  background: rgb(239, 239, 239);
}
.item {
  display: inline;
}
.desc {
  background: white;
  padding: 20px;
}
</style>

<script>
import axios from 'axios';
import { useStore } from 'vuex';
import QCard from '../components/QCard.vue';

export default {
  name: 'HomeView',

  created() {
    this.getData();
    this.store.commit('addTitle', '모델-카테고리');
  },

  data() {
    return {
      store: useStore(),
      tab: 'all',
      targetModel: '전체',
      initData: [],
      publicData: [],
      privateData: [],
      changeinitData: [],
      changepublicData: [],
      changeprivateData: [],
      category: ['스파이크프라임', '브릭큐에센셜', '미설정', 'EV3'],
      categoryDict: {
        36: '스파이크프라임',
        37: '브릭큐에센셜',
        38: '미설정',
        40: 'EV3',
      },
    };
  },
  methods: {
    //카테고리 선택시 동작하는 메소드
    //여러개 선택될 수 있음.
    //얘가 어디서 동작해야 선택될 때 마다 동작할 수 있을까?
    //선택 취소 때문에 원본 데이터 건드리지 말고 복사해서 사용
    //이러면 원본 데이터 3개 복사본 데이터 3개인데 변수 많이 만들기 싫은데 더 좋은 방법 없을까?
    targetChange(targets) {
      if (targets) {
        this.changeinitData = this.initData.filter((data) =>
          this.category.includes(this.categoryDict[data.CategoryId])
        );
        this.changepublicData = this.publicData.filter((data) =>
          this.category.includes(this.categoryDict[data.CategoryId])
        );
        this.changeprivateData = this.privateData.filter((data) =>
          this.category.includes(this.categoryDict[data.CategoryId])
        );
      } else {
        //targets가 비어있는 경우
        //위에 체크 후 해제 된 경우를 생각해야 해서 데이터 초기화 시켜줘야함
        //re init
        this.changeinitData = this.initData;
      }
    },

    getData() {
      axios.get('/api/modelInfo').then((res) => {
        this.initData = res.data;
        this.publicData = res.data.filter((data) => data.IsAvailable === 1);
        this.privateData = res.data.filter((data) => data.IsAvailable === 0);
        //최초에 페이지 렌더링시 아래 친구들 빈 값이라 데이터 초기화 해줘야 함
        this.changeinitData = res.data;
        this.changepublicData = res.data.filter((data) => data.IsAvailable === 1);
        this.changeprivateData = res.data.filter((data) => data.IsAvailable === 0);
      });
    },
  },
  components: {
    QCard,
  },
};
</script>
