<template>
  <q-layout view="lHh lpR fFf">
    <app_layout></app_layout>
    <q-page-container class="page_container">
      <div class="tabs">
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="teal"
          indicator-color="teal"
          align="left"
          narrow-indicator
        >
          <q-tab name="all" label="전체" />
          <q-tab name="public" label="공개" />
          <q-tab name="private" label="비공개" />
        </q-tabs>
        <div class="recent-day">
          <q-item clickable v-ripple>
            <q-item-section avatar> 최근 날짜 </q-item-section>
            <q-item-section>
              <q-icon :name="'unfold_more'"></q-icon>
            </q-item-section>
          </q-item>
        </div>
      </div>
      <q-tab-panels v-model="tab" animated class="panles">
        <q-tab-panel name="all">
          <div class="row">
            <QCard v-for="item in initData" :key="item.name" :cardInfo="item"></QCard>
          </div>
        </q-tab-panel>

        <q-tab-panel name="public">
          <div class="row">
            <QCard v-for="item in publicData" :key="item.name" :cardInfo="item"></QCard>
          </div>
        </q-tab-panel>

        <q-tab-panel name="private">
          <div class="row">
            <QCard v-for="item in privateData" :key="item.name" :cardInfo="item"></QCard>
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </q-page-container>
  </q-layout>
</template>

<style>
.page_contents {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<script>
import axios from 'axios';
import { useStore } from 'vuex';
import QCard from '../components/QCard.vue';

export default {
  name: 'HomeView',

  created() {
    this.getData();
    this.store.commit('addTitle', '모델-최근업로드');
  },

  data() {
    return {
      store: useStore(),
      tab: 'all',
      targetModel: '전체',
      initData: [],
      publicData: [],
      privateData: [],
    };
  },
  methods: {
    //카테고리 선택시 동작하는 메소드
    targetChange(target) {
      this.targetModel = target;
    },

    getData() {
      axios.get('/api/modelInfo').then((res) => {
        this.initData = res.data;
        this.initData.sort((a, b) => {
          return new Date(b.DateAdded) - new Date(a.DateAdded);
        });
        this.publicData = this.initData.filter((data) => data.IsAvailable === 1);
        this.privateData = this.initData.filter((data) => data.IsAvailable === 0);
      });
    },
  },
  components: {
    QCard,
  },
};
</script>
