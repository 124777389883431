<template>
  <q-layout view="lHh lpR fFf">
    <app_layout></app_layout>
    <q-page-container class="page_container">
      <div class="btn">
        <q-btn color="green" @click="confirm = true">태그 추가하기 </q-btn>
      </div>

      <q-dialog v-model="confirm" persistent>
        <q-card>
          <q-card-section class="row items-center">
            <span>태그</span>
            <q-input
              filled
              v-model="name"
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || 'Please type something']"
              class="input"
            ></q-input>
          </q-card-section>

          <q-card-actions align="right">
            <q-btn flat label="취소" color="red" v-close-popup />
            <q-btn flat label="추가하기" color="green" v-close-popup @click="addTag" />
          </q-card-actions>
        </q-card>
      </q-dialog>
      <q-table
        table-header-style=""
        title="Category"
        :rows="rows"
        :columns="columns"
        row-key="Id"
        :pagination="initialPagination"
      >
        <template v-slot:top> </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="Id" :props="props">
              {{ props.row.Id }}
            </q-td>
            <q-td key="title" :props="props">
              {{ props.row.title }}
            </q-td>
            <q-td key="count" :props="props">
              {{ props.row.count }}
            </q-td>
            <q-td key="dateAdded" :props="props">
              {{ props.row.dateAdded }}
            </q-td>
            <q-td key="icon" :props="props" auto-width>
              <q-item v-ripple clickable @click="viewMenu">
                <q-icon :name="'more_vert'"> </q-icon>
              </q-item>
              <q-menu anchor="bottom middle">
                <q-list v-if="viewMenuFlag" bordered separator>
                  <q-item clickable v-ripple @click="modifyTag(props.row.Id, props.row.title)">
                    <q-item-section>수정하기</q-item-section>
                  </q-item>
                  <q-item clickable v-ripple @click="deleteTag(props.row.Id)">
                    <q-item-section>삭제하기</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-page-container>
  </q-layout>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  data() {
    return {
      store: useStore(),
      router: useRouter(),
      viewMenuFlag: false,
      confirm: false,
      initData: [],
      initialPagination: {
        sortBy: 'desc',
        descending: false,
        rowsPerPage: '999999',
      },
      columns: [
        {
          name: 'Id',
          required: true,
          label: 'Id',
          align: 'center',
          field: (row) => row.Id,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: 'title',
          required: true,
          label: '이름',
          align: 'left',
          field: (row) => row.title,
          format: (val) => `${val}`,
        },
        {
          name: 'count',
          required: true,
          label: '수량',
          align: 'center',
          field: (row) => row.count,
          format: (val) => `${val}`,
        },
        {
          name: 'dateAdded',
          required: true,
          label: '만든날짜',
          align: 'center',
          field: (row) => row.dateAdded,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: 'icon',
          required: true,
          align: 'center',
          field: (row) => row.icon,
          format: (val) => `${val}`,
          sortable: true,
        },
      ],
      rows: [],
      tagCounter: {},
      tagId_to_modelId: {},
      tagId_to_tagName: {},
      refTagInfo: [],
      name: '',
      desc: '',
    };
  },
  created() {
    this.store.commit('addTitle', '태그관리');
    this.getData();
  },
  methods: {
    getData() {
      axios.get('/api/get/reftaginfo').then((res) => {
        this.refTagInfo = res.data;
        let tagIdArray = res.data.map((data) => {
          this.tagId_to_modelId[data.ModelTagId] = data.ModelId;
          return data.ModelTagId;
        });
        this.tagCounter = _.countBy(tagIdArray);
      });
      axios.get('/api/get/taginfo').then((res) => {
        this.initData = res.data;
        res.data.map((data) => {
          this.tagId_to_tagName[data.ModelTagId] = data.TagName;
          this.rows.push({
            Id: data.ModelTagId,
            title: data.TagName,
            count: Object.keys(this.tagCounter).includes(data.ModelTagId.toString())
              ? this.tagCounter[data.ModelTagId]
              : 1, //새로 추가한 태그는 refTag에서 카운팅안되므로 1로 처리
            dateAdded: '2023-01-11',
            icon: 'icon',
          });
        });
      });
    },
    viewMenu() {
      this.viewMenuFlag = !this.viewMenuFlag;
    },
    addTag() {
      axios
        .post('/api/post/addtag', {
          TagName: this.name,
        })
        .then((res) => {
          this.router.go(0);
        });
    },
    modifyTag(id, title) {
      // axios.post('/api/post/modifytag', {
      //   CategoryId: id,
      //   Name: title,
      // });
    },
    deleteTag(id) {
      axios
        .post('/api/post/deletetag', {
          ModelTagId: id,
        })
        .then((res) => {
          this.router.go(0);
        });
    },
  },
};
</script>

<style>
.btn {
  display: flex;
  justify-content: end;
}
</style>
