import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import DetailView from '../views/DetailView.vue';
import AddView from '../views/AddView.vue';
import RecentView from '../views/RecentView.vue';
import CategoryView from '../views/CategoryView.vue';
import CategoryManage from '../views/CategoryManage.vue';
import TagView from '../views/TagView.vue';
import TagManage from '../views/TagManage.vue';

import LoginView from '../views/LoginView.vue';
import JoinView from '../views/JoinView.vue';
import com_404 from '../views/404.vue';
import Loading from 'quasar/src/plugins/Loading.js';;

//router는 component에 url params를 전달하기 위해서 선언함.
function loading_hide() {
  Loading.hide();
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: [loading_hide],
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: DetailView,
    beforeEnter: [loading_hide],
  },
  {
    path: '/add',
    name: 'add',
    component: AddView,
    beforeEnter: [loading_hide],
  },
  {
    path: '/recent',
    name: 'recent',
    component: RecentView,
    beforeEnter: [loading_hide],
  },
  {
    path: '/category',
    name: 'category',
    component: CategoryView,
    beforeEnter: [loading_hide],
  },
  {
    path: '/categorymanage',
    name: 'categorymanage',
    component: CategoryManage,
    beforeEnter: [loading_hide],
  },
  {
    path: '/tag',
    name: 'tag',
    component: TagView,
    beforeEnter: [loading_hide],
  },
  {
    path: '/tagmanage',
    name: 'tagmanage',
    component: TagManage,
    beforeEnter: [loading_hide],
  },
  {
    path: '/user/login',
    name: 'login',
    component: LoginView,
    beforeEnter: [loading_hide],
  },
  {
    path: '/join',
    name: 'join',
    component: JoinView,
    beforeEnter: [loading_hide],
  },
  /* 404 */
  {
    path: '/:pathMatch(.*)*',
    component: com_404,
    beforeEnter: [loading_hide],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
