<template>
  <q-layout view="lHh lpR fFf">
    <app_layout></app_layout>
    <q-page-container class="page_container">
      <div>
        <div class="page_contents">
          <q-input
            v-model="search"
            outlined
            placeholder="검색어 입력"
            style="width: 250px"
            @update:model-value="getSearch"
          >
            <template v-slot:append>
              <q-item clickable @click="alert = true">
                <q-icon :name="'settings_input_component'" />
              </q-item>
            </template>
          </q-input>
          <q-btn icon="search" flat dense @click="getSearch" />
        </div>

        <q-dialog v-model="alert">
          <div class="dialog">
            <p>상세 검색</p>
            <div class="field">
              <span class="title">카테고리</span>
              <q-select filled v-model="category" :options="categoryOpt" class="selector" />
            </div>
            <div class="field">
              <span class="title">공개</span>
              <q-select filled v-model="isAvailable" :options="isAvailableOpt" class="selector" />
            </div>
            <div class="field">
              <span class="title">날짜</span>
              <q-select filled v-model="date" :options="dateOpt" class="selector" />
            </div>
            <div class="field">
              <span class="title">저작권</span>
              <q-select filled v-model="copyright" :options="copyrightOpt" class="selector" />
            </div>
            <div class="btn-group">
              <q-btn color="green" @click="detailSearch">검색</q-btn>
            </div>
          </div>
        </q-dialog>

        <div class="desc"></div>
        <div class="tabs">
          <q-tabs
            v-model="tab"
            dense
            class="text-grey"
            active-color="teal"
            indicator-color="teal"
            align="left"
            narrow-indicator
          >
            <q-tab name="all" label="전체" />
            <q-tab name="public" label="공개" />
            <q-tab name="private" label="비공개" />
          </q-tabs>
          <div class="recent-day">
            <q-item clickable v-ripple @click="viewMenu">
              <q-item-section avatar> 최근 날짜 </q-item-section>
              <q-item-section>
                <q-icon :name="'unfold_more'"></q-icon>
              </q-item-section>
            </q-item>
            <q-menu anchor="bottom middle">
              <q-list v-if="viewMenuFlag" bordered separator>
                <q-item clickable v-ripple>
                  <q-item-section>이름</q-item-section>
                </q-item>
                <q-item clickable v-ripple>
                  <q-item-section>인기도</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </div>
        </div>

        <div class="tab-panels">
          <q-tab-panels v-model="tab" animated class="panles">
            <q-tab-panel name="all">
              <div class="row">
                <QCard v-for="item in searchInitData" :key="item" :cardInfo="item"></QCard>
              </div>
            </q-tab-panel>

            <q-tab-panel name="public">
              <div class="row">
                <QCard v-for="item in searchPublicData" :key="item" :cardInfo="item"></QCard>
              </div>
            </q-tab-panel>

            <q-tab-panel name="private">
              <div class="row">
                <QCard v-for="item in searchPrivateData" :key="item" :cardInfo="item"></QCard>
              </div>
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </div>
    </q-page-container>
  </q-layout>
</template>

<style>
p {
  text-align: center;
  padding-top: 20px;
  font-weight: bold;
  font-size: 16px;
}

.title {
  margin-left: 20px;
}

.dialog {
  background: white;
  width: 600px;
  height: 600px;
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: center; */
  /* text-align: center; */
}
.field {
  display: flex;
  /* margin: 20px 0; */
  align-items: center;
  justify-content: space-between;
}
.btn-group {
  display: flex;
  justify-content: center;
}
.tabs {
  background: white;
  position: relative;
}
.tab-panels {
  border: 1px solid gray;
}
.recent-day {
  position: absolute;
  top: 3px;
  right: 10px;
  /* background: red; */
}
.page_contents {
  display: flex;
  align-items: center;
  justify-content: center;
}
.desc {
  background: white;
  padding: 20px;
}
</style>

<script>
import axios from 'axios';
import { useStore } from 'vuex';
import QCard from '../components/QCard.vue';

export default {
  name: 'HomeView',

  created() {
    this.getData();
    this.store.commit('addTitle', '홈');
  },

  data() {
    return {
      store: useStore(),
      alert: false,
      viewMenuFlag: false,
      tab: 'all',
      initData: [],
      publicData: [],
      privateData: [],
      searchInitData: [],
      searchPublicData: [],
      searchPrivateData: [],
      search: '',

      category: '전체',
      categoryOpt: [],
      categoryDict: {},

      isAvailable: '전체',
      isAvailableOpt: ['전체', '공개', '비공개'],

      date: '전체',
      dateOpt: ['전체', '최근 일주일', '한달', '1년'],

      copyright: '전체',
      copyrightOpt: ['전체', '저작권없음', '저작권일부', '저작권 Free', '저작권확보'],
    };
  },
  methods: {
    getData() {
      axios.get('/api/modelInfo').then((res) => {
        this.initData = res.data;
        this.searchInitData = res.data;
        this.publicData = res.data.filter((data) => data.IsAvailable === 1);
        this.searchPublicData = res.data.filter((data) => data.IsAvailable === 1);
        this.privateData = res.data.filter((data) => data.IsAvailable === 0);
        this.searchPrivateData = res.data.filter((data) => data.IsAvailable === 0);
      });

      axios.get('/api/get/categoryinfo').then((res) => {
        let temp = [];
        res.data.map((data) => {
          temp.push(data.Name);
          this.categoryDict[data.CategoryId] = data.Name;
        });
        temp.unshift('전체');
        this.categoryOpt = temp;
      });
    },
    viewMenu() {
      this.viewMenuFlag = !this.viewMenuFlag;
    },
    // temp() {
    //   // initData :: origin_Data
    //   // searchInitData : [],
    //   // searchPublicData: [],
    //   // searchPrivateData: [],
    //   if (this.tab == '전체') {
    //     let list = JSON.parse(JSON.stringify(this.initData));
    //     for (var k in list) {
    //       console.log(k);
    //     }
    //     for (var i = list.length - 1; i >= 0; i--) {
    //       if (this.category != '전체' && list[i].CategoryId != categoryDict[this.category]) {
    //         list.splice(i, 1);
    //         continue;
    //       }
    //       if (this.category != '전체' && list[i].CategoryId != categoryDict[this.category]) {
    //         list.splice(i, 1);
    //         continue;
    //       }
    //       if (this.category != '전체' && list[i].CategoryId != categoryDict[this.category]) {
    //         list.splice(i, 1);
    //         continue;
    //       }
    //     }
    //     this.searchInitData = list;
    //   } else if (this.tab == '공개') {
    //   } else if (this.tab == '비공개') {
    //   }
    // },
    getSearch() {
      // console.log(this.search);
      this.searchInitData = this.initData.filter((data) => data.Title.includes(this.search));
      this.searchPublicData = this.publicData.filter((data) => data.Title.includes(this.search));
      this.searchPrivateData = this.privateData.filter((data) => data.Title.includes(this.search));

      if (!this.search) {
        this.getData();
      }
    },
    detailSearch() {
      const availableToTab = { 전체: 'all', 공개: 'public', 비공개: 'private' };

      this.tab = availableToTab[this.isAvailable];
      switch (this.tab) {
        case 'all':
          if (this.category !== '전체') {
            this.searchInitData = this.searchInitData.filter((data) => {
              return this.categoryDict[data.CategoryId] === this.category;
            });
          }
          //category === this.category인 애들만 남음

          if (this.copyright !== '전체') {
            this.searchInitData = this.searchInitData.filter((data) => {
              return copyrightToOpt[data.CopyrightStep] === this.copyright;
            });
          }
          //searchInitData에서 카테고리, 저작권으로 필터링 된 애들만 남음

          if (this.date !== '전체') {
            this.searchInitData = this.searchInitData.filter((data) => {
              return this.getDateDiff(data.DateAdded.slice(0, 10)) < dateToOpt[this.date];
            });
          }
        case 'public':
          if (this.category !== '전체') {
            this.searchPublicData = this.searchPublicData.filter((data) => {
              return this.categoryDict[data.CategoryId] === this.category;
            });
          }
          //category === this.category인 애들만 남음

          if (this.copyright !== '전체') {
            this.searchPublicData = this.searchPublicData.filter((data) => {
              return copyrightToOpt[data.CopyrightStep] === this.copyright;
            });
          }
          //searchPublicData에서 카테고리, 저작권으로 필터링 된 애들만 남음

          if (this.date !== '전체') {
            this.searchPublicData = this.searchPublicData.filter((data) => {
              return this.getDateDiff(data.DateAdded.slice(0, 10)) < dateToOpt[this.date];
            });
          }
        case 'private':
          if (this.category !== '전체') {
            this.searchPrivateData = this.searchPrivateData.filter((data) => {
              return this.categoryDict[data.CategoryId] === this.category;
            });
          }
          //category === this.category인 애들만 남음

          if (this.copyright !== '전체') {
            this.searchPrivateData = this.searchPrivateData.filter((data) => {
              return copyrightToOpt[data.CopyrightStep] === this.copyright;
            });
          }
          //searchPrivateData에서 카테고리, 저작권으로 필터링 된 애들만 남음

          if (this.date !== '전체') {
            this.searchPrivateData = this.searchPrivateData.filter((data) => {
              return this.getDateDiff(data.DateAdded.slice(0, 10)) < dateToOpt[this.date];
            });
          }
      }
      this.alert = !this.alert;
    },
    getDateDiff(beforeDate) {
      const before = new Date(beforeDate);
      const current = new Date();
      const diffDate = current.getTime() - before.getTime();
      return Math.abs(diffDate / (1000 * 60 * 60 * 24));
    },
  },
  components: {
    QCard,
  },
};
</script>
