<template>
  <q-list class="q-list">
    <q-item-label header class="label">과사람</q-item-label>
    <!-- <q-item
      clickable
      v-ripple
      style="height: 40px"
      v-for="(menu, index) in left_menu"
      :key="index"
      :class="{ selection: menu.is_selected }"
      @click="goto_link(menu)"
    >
      <q-item-section avatar>
        <q-icon color="gray" :name="menu.icon"></q-icon>
      </q-item-section>
      <q-item-section>{{ menu.title }}</q-item-section>
    </q-item> -->

    <q-item clickable v-ripple style="height: 40px" :class="{ selection: false }" to="/">
      <q-item-section avatar>
        <q-icon color="gray" :name="'home'"></q-icon>
      </q-item-section>
      <q-item-section avatar>홈</q-item-section>
    </q-item>

    <q-expansion-item :label="'모델'" :icon="'directions_car'" default-opened>
      <q-item clickable v-ripple to="/recent">
        <q-item-section avatar>
          <q-icon color="gray" :name="'-'"></q-icon>
        </q-item-section>
        <q-item-section avatar>최근 업로드</q-item-section>
      </q-item>
      <q-item clickable v-ripple to="/category">
        <q-item-section avatar>
          <q-icon color="gray" :name="'-'"></q-icon>
        </q-item-section>
        <q-item-section avatar>카테고리</q-item-section>
      </q-item>
      <q-item clickable v-ripple to="/tag">
        <q-item-section avatar>
          <q-icon color="gray" :name="'-'"></q-icon>
        </q-item-section>
        <q-item-section avatar>태그</q-item-section>
      </q-item>
      <q-item clickable v-ripple to="/add">
        <q-item-section avatar>
          <q-icon color="gray">-</q-icon>
        </q-item-section>
        <q-item-section avatar>모델추가</q-item-section>
      </q-item>
      <q-item>
        <q-item-section avatar>
          <q-icon color="gray" :name="'-'"></q-icon>
        </q-item-section>
        <q-item-section avatar>앨범</q-item-section>
      </q-item>
    </q-expansion-item>

    <q-expansion-item :label="'내 작업'" :icon="'person'" default-opened>
      <q-item>
        <q-item-section avatar>
          <q-icon color="gray" :name="'-'"></q-icon>
        </q-item-section>
        <q-item-section avatar>내 목록</q-item-section>
      </q-item>
      <q-item>
        <q-item-section avatar>
          <q-icon color="gray" :name="'-'"></q-icon>
        </q-item-section>
        <q-item-section avatar>내 앨범</q-item-section>
      </q-item>
      <q-item>
        <q-item-section avatar>
          <q-icon color="gray" :name="'-'"></q-icon>
        </q-item-section>
        <q-item-section avatar>내 업로드</q-item-section>
      </q-item>
    </q-expansion-item>

    <q-expansion-item :label="'설정'" :icon="'settings'" default-opened>
      <q-item to="/categorymanage">
        <q-item-section avatar>
          <q-icon color="gray" :name="'-'"></q-icon>
        </q-item-section>
        <q-item-section avatar>카테고리 관리</q-item-section>
      </q-item>
      <q-item to="/tagmanage">
        <q-item-section avatar>
          <q-icon color="gray" :name="'-'"></q-icon>
        </q-item-section>
        <q-item-section avatar>태그 관리</q-item-section>
      </q-item>
    </q-expansion-item>

    <!--  -->

    <!-- <q-expansion-item
      group="menu"
      class="left_menu pb2"
      expand-separator
      :class="{ active: menu.is_selected }"
      :icon="menu.icon"
      :label="menu.title"
      v-model="menu.is_selected"
      @click="goto_link(menu)"
    >
      <q-item clickable v-ripple @click="goto_link(item)" v-for="item in menu.children">
        <q-item-section avatar></q-item-section>
        <q-item-section> {{ item.title }} </q-item-section>
        <template v-if="item.children.length > 0">
          <q-item-section side>
            <q-icon name="keyboard_arrow_right"></q-icon>
          </q-item-section>
          <com-left_pop_menu :menu="item"></com-left_pop_menu>
        </template>
      </q-item>
    </q-expansion-item> -->
  </q-list>
</template>

<style>
.q-list {
  color: black;
  /* background: rgb(216, 215, 215); */
}
.label {
  text-align: center;
  padding: 20px;
  color: white;
  background: rgb(119, 119, 119);
}
.selection {
  border-left: 3px solid #ffffff;
}
/* .expansion-item {
  display: inline;
} */
.q-item__section--avatar {
  min-width: 40px;
}
.q-expansion-item.active {
  background-color: #333;
}
.q-expansion-item.left_menu > .q-expansion-item__container > .q-item {
  min-height: 36px;
}
</style>

<script>
export default {
  name: 'com-left_menu',
  data: function () {
    return {
      left_menu: [
        {
          title: '홈',
          icon: 'home',
          route: 'home',
          is_selected: false,
          link: '/home',
        },
        {
          title: '모델',
          icon: 'directions_car',
          route: 'notice',
          is_selected: false,
          link: '/notice',
        },
        {
          title: '설정',
          icon: 'settings',
          route: 'myPage',
          is_selected: false,
          link: '/myPage',
        },
        // { title : '설정', icon : 'settings', route:"setting", is_selected: false,
        // 	link : '/setting' },
      ],
    };
  },
  methods: {
    leftMenu_click: function (route) {
      var i;
      for (i = 0; i < this.left_menu.length; i++) {
        if (route == this.left_menu[i].route) {
          if (this.left_menu[i].is_selected == true) {
            this.left_menu[i].is_selected = false;
          } else {
            this.left_menu[i].is_selected = true;
          }
        } else {
          this.left_menu[i].is_selected = false;
        }
      }
    },
    goto_link: function (menu) {
      if (menu.link) {
        this.$router.push(menu.link);
      }
    },
  },
  mounted: function () {
    var route_list = this.$route.path.split('/');
    var route = '';
    if (route_list.length >= 2) {
      route = route_list[1];
    }
    this.leftMenu_click(route);
  },
};
</script>
