<template>
  <q-layout view="lHh lpR fFf">
    <app_layout></app_layout>
    <q-page-container class="page_container">
      <div class="desc">태그</div>
      <div class="cloud">
        <canvas id="word_cloud" class="word_cloud" width="500" height="500"></canvas>
      </div>
      <div class="tabs">
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="teal"
          indicator-color="teal"
          align="left"
          narrow-indicator
        >
          <q-tab name="all" label="전체" />
          <q-tab name="public" label="공개" />
          <q-tab name="private" label="비공개" />
        </q-tabs>
        <div class="recent-day">
          <q-item clickable v-ripple>
            <q-item-section avatar> 최근 날짜 </q-item-section>
            <q-item-section>
              <q-icon :name="'unfold_more'"></q-icon>
            </q-item-section>
          </q-item>
        </div>
      </div>
      <q-tab-panels v-model="tab" animated class="panles">
        <q-tab-panel name="all">
          <div class="row">
            <QCard v-for="item in initData" :key="item.name" :cardInfo="item"></QCard>
          </div>
        </q-tab-panel>

        <q-tab-panel name="public">
          <div class="row">
            <QCard v-for="item in publicData" :key="item.name" :cardInfo="item"></QCard>
          </div>
        </q-tab-panel>

        <q-tab-panel name="private">
          <div class="row">
            <QCard v-for="item in privateData" :key="item.name" :cardInfo="item"></QCard>
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </q-page-container>
  </q-layout>
</template>

<style>
.cloud {
  display: flex;
  background: white;
  align-items: center;
  justify-content: center;
}
#word_cloud {
  display: block;
  background: white;
  align-items: center;
  justify-content: center;
}
.page_contents {
  display: flex;
  align-items: center;
  justify-content: center;
}
.desc {
  background: white;
  padding: 20px;
}
</style>

<script>
import axios from 'axios';
import _ from 'lodash';
import { useStore } from 'vuex';
import WordCloud from '../wordcloud2.js';
import wordcloud from './WordCloud.vue';
import Cloud from 'vue-d3-cloud';
import QCard from '../components/QCard.vue';

export default {
  name: 'HomeView',

  created() {
    this.getData();
    this.store.commit('addTitle', '모델-태그');
  },

  data() {
    return {
      store: useStore(),
      tab: 'all',
      targetModel: '전체',
      initData: [],
      publicData: [],
      privateData: [],
      initTagList: [],
      tagList: [],
      tagCount: [],
    };
  },
  methods: {
    getData() {
      axios.get('/api/modelInfo').then((res) => {
        this.initData = res.data;
        this.publicData = res.data.filter((data) => data.IsAvailable === 1);
        this.privateData = res.data.filter((data) => data.IsAvailable === 0);

        //getTags
        res.data.forEach((data) => {
          //이중 루프 풀기
          this.initTagList = [...this.initTagList, ...data.Tags.split(' ')];
        });
        for (var tag of this.initTagList) {
          if (tag === '') {
            //태그 빈 경우 제거
            continue;
          } else if (tag[0] === '#') {
            //#제거
            this.tagList.push(tag.slice(1));
          } else {
            this.tagList.push(tag);
          }
        }
        this.tagCount = Object.entries(_.countBy(this.tagList));
        let options = {
          list: this.tagCount,
          fontWeight: 600,
          gridSize: Math.round((2 * document.getElementById('word_cloud').offsetWidth) / 1024),
          weightFactor: function (size) {
            return (Math.pow(size, 3) * document.getElementById('word_cloud').offsetWidth) / 1024;
          },
          click: function (item, dimension, event) {
            // console.log('click!');
            // console.log(event.currentTarget);
            // console.log(item);
          },
          hover: function (item, dimension, event) {
            // console.log(event.currentTarget);
          },
        };
        WordCloud.minFontSize;
        WordCloud(document.getElementById('word_cloud'), options);
      });
    },
  },
  components: {
    QCard,
    wordcloud,
    Cloud,
  },
};
</script>
