<template>
  <q-layout view="lHh lpR fFf">
    <app_layout></app_layout>
    <q-page-container class="page_container">
      <div class="page_main">
        <q-form @submit="onSubmit">
          <div class="container">
            <div class="field">
              <span>이름</span>
              <q-input
                filled
                v-model="title"
                lazy-rules
                :rules="[(val) => (val && val.length > 0) || 'Please type something']"
                class="input"
              ></q-input>
            </div>
            <div class="field">
              <span>설명</span>
              <q-editor
                v-model="desc"
                :definitions="{
                  save: {
                    tip: 'Save your work',
                    icon: 'save',
                    handler: saveWork,
                  },
                  link: {
                    icon: 'link',
                    handler: uploadIt,
                  },
                }"
                toolbar-bg="gray"
                :toolbar="[['bold', 'save', 'link']]"
                class="editor"
              />
            </div>
            <div class="field">
              <span>카테고리</span>
              <q-select filled v-model="category" :options="options" class="selector" />
            </div>
            <div class="field">
              <span>태그</span>
              <q-input
                filled
                v-model="tags"
                class="input"
                error-message="태그 시작은 #이어야 합니다. 태그 구분은 스페이스로만 가능합니다."
                :error="isTagError"
              ></q-input>
            </div>
            {{ isTag }}
            <div class="field">
              <span>고유ID</span>
              <q-input
                filled
                v-model="originId"
                class="input"
                error-message="영어, 언더바, 숫자만 가능합니다."
                :error="isOriginError"
              ></q-input>
            </div>
            <div id="uploadimage" class="field">
              <span id="imagetitle"> 이미지업로드 </span>
              <q-file
                @update:model-value="uploadImages"
                style="display: none"
                multiple
                class="real-upload"
              />
              <div @drop.prevent="dropInputImages($event)" @dragover.prevent id="multiuploader">
                <q-carousel
                  animated
                  v-model="slide"
                  control-color="green"
                  arrows
                  navigation
                  infinite
                  height="400px"
                >
                  <q-carousel-slide v-for="(url, idx) in imageUrlList" :key="url" :name="idx + 1">
                    <q-img height="400px" :src="url" />
                  </q-carousel-slide>
                </q-carousel>
              </div>
            </div>

            <div class="field">
              <span>대표이미지</span>
              <div @drop.prevent="dropInputCover($event)" @dragover.prevent id="uploader">
                <q-img height="400px" :src="coverImage" />
              </div>
            </div>
            <div class="field">
              <span>저작권단계</span>
              <q-tabs
                v-model="copyrightTab"
                indicator-color="transparent"
                active-bg-color="green"
                active-color="white"
                class="bg-white text-grey-5 shadow-2"
                id="tabs"
              >
                <q-tab name="none" label="저작권없음" />
                <q-tab name="part" label="저작권일부" />
                <q-tab name="free" label="저작권 free" />
                <q-tab name="secure" label="저작권확보" />
              </q-tabs>
            </div>
            <div class="field">
              <span>저작권메모</span>
              <q-input v-model="copyrightMemo" filled class="input"></q-input>
            </div>
            <div class="field">
              <span>공개설정</span>
              <q-toggle v-model="isOpen" color="green" class="toggle" />
            </div>
            <div id="share" class="field">
              <span>공유</span>
              <q-input v-model="shareLink" filled class="input"></q-input>
            </div>
            <div class="copy-icon">
              <q-icon :name="'content_copy'" v-ripple @click="contentCopy"></q-icon>
            </div>
          </div>
          <div class="btn-group">
            <q-btn type="submit" color="green" id="btn">추가하기</q-btn>
          </div>
        </q-form>
      </div>
    </q-page-container>
  </q-layout>
</template>

<script>
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// window.onload = function () {
//   const realUpload = document.querySelector('.real-upload');
//   const multiupload = document.querySelector('#multiuploader');
//   multiupload.addEventListener('click', () => realUpload.click());
// };

export default {
  data() {
    return {
      store: useStore(),
      router: useRouter(),
      slide: 1,
      options: ['스파이크프라임', '브릭큐에센셜', '미설정', 'EV3'],
      title: '',
      desc: '',
      category: '',
      tags: '',
      originId: '',
      imageUrlList: [],
      imageFileList: [],
      coverImage: '',
      coverFile: '',
      copyrightTab: 'none',
      copyrightMemo: 'MIT Lisence',
      shareLink: 'www.abc.com',
      isOpen: true,
    };
  },
  methods: {
    contentCopy() {
      // console.log('Copy complete!');
    },
    rightBtn() {
      // console.log("rightClick");
    },
    onSubmit() {
      let coverData = new FormData();
      let imageData = new FormData();
      coverData.append('Id', this.originId);
      coverData.append('isCover', 'cover_');
      coverData.append('Title', this.title);
      coverData.append('CoverFile', this.coverFile);
      imageData.append('Id', this.originId);
      imageData.append('isCover', '');
      imageData.append('Title', this.title);
      this.imageFileList.map((data) => imageData.append('ImageFile', data));

      axios
        .post('/api/post/addinfo', {
          Title: this.title,
          Description: this.desc,
          CategoryId: this.category,
          Tags: this.tags,
          CoverURL: this.coverImage,
          Id: this.originId,
          End: this.imageUrlList.length,
          CopyrightStep: this.copyrightTab,
          CopyrightMemo: this.copyrightMemo,
          IsAvailable: this.isOpen,
          Writer: this.store.state.nickName,
          ShareLink: this.shareLink,
        })
        .then((res) => {
          console.log(res.data.message);
        })
        .catch((err) => console.log(err.response.data));

      axios
        .post('/api/post/addimages', imageData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => console.log(res.data.message));
      axios
        .post('/api/post/addcover', coverData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.success) {
            console.log(data.message);
            this.router.push({ path: '/recent' });
          }
          if (!data.success) {
            alert(data.message);
          }
        });
    },

    //클릭시 이미지 추가 창 띄우기
    uploadImages(files) {
      files.map((file, idx) => {
        // console.log(file);
        // this.imageFileList.append('file', file);
        this.imageFileList.push(file);
        this.imageUrlList.push(URL.createObjectURL(file));
      });
    },
    dropInputImages(event) {
      let files = Array.from(event.dataTransfer.files, (v) => v);
      files.map((file, idx) => {
        this.imageFileList.push(file);
        this.imageUrlList.push(URL.createObjectURL(file));
      });
    },
    //클릭시 이미지 추가 창 띄우기
    uploadCover(file) {
      this.coverFile = file;
      this.coverImage = URL.createObjectURL(file);
    },
    dropInputCover(event) {
      let file = Array.from(event.dataTransfer.files, (v) => v)[0];
      this.coverFile = file;
      this.coverImage = URL.createObjectURL(file);
    },
  },
  created() {
    this.store.commit('addTitle', '모델추가');
  },
  computed: {
    isTagError() {
      if (this.tags.includes(',')) {
        return true;
      }
      for (var item of this.tags.split(' ')) {
        if (item[0] !== '#') {
          return true;
        }
      }
    },
    isOriginError() {
      const regex = /^[A-Za-z0-9_]+$/;
      if (!regex.test(this.originId)) {
        return true;
      }
    },
  },
};
</script>

<style>
img {
  width: 300px;
  height: 300px;
}
#uploadimage {
  position: relative;
}

#navigator {
  width: 100vw;
  display: flex;
  align-items: center;
  margin-left: 100px;
}
#multiuploader {
  position: relative;
  width: 80%;
  margin-right: 10px;
  border: 0.5px solid white;
  border-radius: 5px;
  box-shadow: 0px 1px 3px #808080;
}
#uploader {
  width: 80%;
  height: 400px;
  margin-right: 10px;
  border: 0.5px solid white;
  border-radius: 5px;
  box-shadow: 0px 1px 3px #808080;
}
#share {
  position: relative;
}
#btn {
  width: 30%;
}
#thumbnail {
  display: flex;
  flex-direction: row;
}
#item-section-image {
  width: 300px;
  height: 300px;
}
.dragdrop {
  height: 500px;
  border: 3px solid red;
}
.input {
  width: 80%;
  margin-right: 10px;
}
.editor {
  width: 80%;
  margin-right: 10px;
}
.selector {
  width: 80%;
  margin-right: 10px;
}
.uploader {
  width: 80%;
}

.innerBtn {
  /* padding: 5px; */
  margin-left: 5px;
}
#tabs {
  margin-right: 30%;
}
.toggle {
  margin-right: 76%;
}
.copy-icon {
  position: absolute;
  bottom: 130px;
  right: 60px;
}
.page_main {
  padding: 20px;
  background: white;
}
.container {
  border: 1px solid #808080;
  border-radius: 5px;
  padding: 20px;
  background: white;
  /* margin: 10px 0; */
}
.field {
  display: flex;
  align-items: center;
  margin: 20px 0;
  justify-content: space-between;
  /* align: left; */
  /* align-items: ; */
}
.btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
/* .field:nth-child(8) {
  display: flex;
  align-items: center;
  margin-right: 39%;
  justify-content: space-evenly;
  /* justify-content: flex-start; */
/* }  */
</style>
