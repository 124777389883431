<template>
  <div class="q-pa-md">
    <div v-if="this.store.state.removeItems.includes(cardInfo.Id)">삭제된 항목입니다!</div>
    <div>
      <div class="visible">
        <q-icon v-if="cardInfo.IsAvailable === 0" :name="'visibility_off'"></q-icon>
      </div>
      <div class="col">
        <img :src="imageURL" class="image" />
        <div class="btn-group">
          <div class="sub-title">{{ cardInfo.Title }}</div>
          <div>
            <span>{{ cardInfo.DateAdded.substr(0, 10) }}</span>
            <q-icon :name="'more_vert'" @click="viewMenu" v-ripple></q-icon>
          </div>
        </div>
        <q-menu anchor="bottom middle">
          <q-list v-if="viewMenuFlag" bordered separator>
            <q-item clickable v-ripple :to="'/detail/' + cardInfo.ModelId">
              <q-item-section avatar>
                <q-icon color="gray" :name="'list'"></q-icon>
              </q-item-section>
              <q-item-section>상세정보</q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section avatar>
                <q-icon color="gray" :name="'slideshow'"></q-icon>
              </q-item-section>
              <q-item-section>미리보기</q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section avatar>
                <q-icon color="gray" :name="'share'"></q-icon>
              </q-item-section>
              <q-item-section>공유</q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section avatar>
                <q-icon color="gray" :name="'create_new_folder'"></q-icon>
              </q-item-section>
              <q-item-section>목록에 추가</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';

export default {
  data() {
    return {
      store: useStore(),
      viewMenuFlag: false,
    };
  },
  props: {
    cardInfo: {
      type: Object,
    },
  },
  computed: {
    imageURL() {
      // console.log(this.cardInfo);
      return (
        '/images/' +
        this.cardInfo.Id +
        '/' +
        this.getFileName(this.cardInfo.CoverURL)
      );
    },
  },
  methods: {
    viewMenu() {
      this.viewMenuFlag = !this.viewMenuFlag;
    },
    getFileName(data) {
      let splitUrl = data.split('/');
      let len = splitUrl.length;
      return splitUrl[len - 1];
    },
  },
};
</script>

<style>
.sub-title {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.image {
  width: 200px;
  height: 200px;

  min-width: 200px;
  min-height: 200px;

  max-width: 200px;
  max-height: 200px;
}
.q-pa-md {
  position: relative;
}
.visible {
  /* float: right; */
  position: absolute;
  top: 30px;
  right: 40px;
}
.btn-group {
  /* margin: 8px; */
  display: flex;
  justify-content: space-between;
}
.my-card {
  width: 250px;
  height: 250px;
  margin: 0 auto;
}
.text-subtitle2 {
  text-align: right;
}
.private {
  text-align: right;
  color: red;
}
.row > div {
  text-align: center;
}
</style>
