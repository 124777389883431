import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Quasar from 'quasar/src/vue-plugin.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import quasarUserOptions from './quasar-user-options';

import './assets/libs/quasar2.css';
import './assets/libs/common.css';
import $c from './assets/libs/common.js';
import './assets/libs/common_sns.js';

import app_layout from './components/app_layout';
import com_left_menu from './components/layout_left_menu';
import com_left_pop_menu from './components/layout_left_pop_menu';
import com_top_menu from './components/layout_top_menu';

const app = createApp(App);

//global component로 등록
app.component('com-left_pop_menu', com_left_pop_menu);
app.component('com-top_menu', com_top_menu);
app.component('com-left_menu', com_left_menu);
app.component('app_layout', app_layout);

app.config.globalProperties.$c = $c;
app.use(Quasar, quasarUserOptions);
app.use(store);
app.use(router);
app.mount('#app');
