<template>
  <q-layout view="lHh lpR fFf">
    <q-page-container>
      <div class="row login-page">
        <div class="col-5 sidenav">
          <div class="space"></div>
          <div class="login-main-text">
            <h4 class="text-center">L코어빌더 관리자</h4>
            <p class="text-center">
              리포터 앱 서비스는 과사람AI의 기능개선과 문제점을 개선하기 위하여 여러분의 관심과
              지원을 요청합니다.
            </p>
            <p class="text-center">더 나은 과사람을 위하여~</p>
          </div>
        </div>
        <div class="col-7 main">
          <div class="space"></div>
          <div class="login-form">
            <h5 class="mb2">로그인 하기</h5>
            <div class="q-mb-lg">아이디와 비밀번호를 입력하세요.</div>

            <form autocomplete="off" @submit="handleLogin">
              <div class="q-mt-sm">
                <label>아이디</label>
              </div>
              <q-input
                outlined
                bg-color="gray"
                class="pb2"
                placeholder="아이디"
                :error="form_error.UserName ? true : false"
                :error-message="form_error.UserName"
                v-model="form.UserName"
                @keyup.enter="handleLogin"
              >
                <template #prepend>
                  <q-icon name="perm_identity" />
                </template>
              </q-input>

              <div class="q-mt-sm p-relative">
                <label>비밀번호</label>
                <span class="absolute-right">비밀번호 찾기</span>
              </div>
              <q-input
                outlined
                class="pb2"
                placeholder="비밀번호"
                type="password"
                :error="form_error.Password ? true : false"
                :error-message="form_error.Password"
                v-model="form.Password"
                @keyup.enter="handleLogin"
              >
                <template #prepend>
                  <q-icon name="lock_open" />
                </template>
              </q-input>
              <div class="q-pt-md">
                <q-checkbox
                  v-model="remember_password"
                  label="Remember Password"
                  @update:model-value="change_remember"
                />
              </div>

              <!-- <div class="q-pt-md">
                <label>내 학원</label>
              </div> -->
              <!-- <q-select
                outlined
                placeholder="센터이름"
                :error="form_error.CenterId ? true : false"
                :error-message="form_error.CenterId"
                :options="center_list"
                v-model="form.center"
              >
                <template #option="scope">
                  <q-item v-bind="scope.itemProps" @click="form.CenterId = scope.opt.CenterId">
                    <q-item-section>
                      {{ scope.opt.CenterName }}
                    </q-item-section>
                  </q-item>
                </template>
              </q-select> -->

              <div>
                <q-btn
                  color="positive"
                  text-color="white"
                  class="full-width"
                  label="로그인 하기"
                  @click="handleLogin"
                ></q-btn>
              </div>

              <div class="q-mt-md">
                Dont' have an account?
                <span
                  style="color: #1ea1f7; text-decoration: underline; cursor: pointer"
                  @click="goJoin"
                  >회원가입</span
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </q-page-container>
  </q-layout>
</template>

<style>
.login-page {
  position: fixed;
  width: 100%;
  height: 100%;
}
.login-page .sidenav {
  display: inline-block;
  height: 100%;
  background-color: #07bf6f;
  overflow-x: hidden;
}
.space {
  height: 20%;
}
.login-page .login-main-text {
  padding: 60px;
  color: #fff;
}
.login-page .login-main-text p {
  color: #fff;
}
.login-page .main {
  display: inline-block;
  height: 100%;
}
.login-page .login-form {
  padding: 0 30%;
}
.btn-black {
  background-color: #000 !important;
  color: #fff;
}
</style>

<script>
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'LoginView',
  data: function () {
    return {
      form: {
        UserName: '',
        Password: '',
        CenterId: 0,
        center: null,
      },
      form_error: {},
      remember_password: false,
      center_list: [{ CenterName: '해운센터' }, { CenterName: '사직' }],
      router: useRouter(),
      store: useStore(),
    };
  },

  methods: {
    handleLogin() {
      console.log(this.form);
      axios
        .post('/api/login', {
          id: this.form.UserName,
          pw: this.form.Password,
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            localStorage.setItem('token', data.token);
            // window.location.href = '/#/home';
            this.$router.push('/');
            // console.log(this.$router.getRoutes());
            this.store.commit('addNickName', data.name);
          } else {
            alert(data.message);
          }
        });
    },
    goJoin() {
      this.router.push('/join');
    },
  },
};
</script>
