<template>
  <q-layout view="lHh lpR fFf">
    <q-page-container>
      <div class="row login-page">
        <div class="col-5 sidenav">
          <div class="space"></div>
          <div class="login-main-text">
            <h4 class="text-center">L코어빌더 관리자</h4>
            <p class="text-center">
              리포터 앱 서비스는 과사람AI의 기능개선과 문제점을 개선하기 위하여 여러분의 관심과
              지원을 요청합니다.
            </p>
            <p class="text-center">더 나은 과사람을 위하여~</p>
          </div>
        </div>
        <div class="col-7 main">
          <div class="space"></div>
          <div class="login-form">
            <h5 class="mb2">로그인 하기</h5>
            <div class="q-mb-lg">아이디와 비밀번호를 입력하세요.</div>

            <form autocomplete="off" @submit="handleLogin">
              <div class="q-mt-sm">
                <label>아이디</label>
              </div>
              <q-input
                outlined
                bg-color="gray"
                class="pb2"
                placeholder="아이디"
                @keyup.enter="handleLogin"
              >
                <template #prepend>
                  <q-icon name="perm_identity" />
                </template>
              </q-input>

              <div class="q-mt-sm p-relative">
                <label>비밀번호</label>
                <span class="absolute-right">비밀번호 찾기</span>
              </div>
              <q-input
                outlined
                class="pb2"
                placeholder="비밀번호"
                type="password"
                @keyup.enter="handleLogin"
              >
                <template #prepend>
                  <q-icon name="lock_open" />
                </template>
              </q-input>
              <div class="q-pt-md">
                <q-checkbox
                  v-model="remember_password"
                  label="Remember Password"
                  @update:model-value="change_remember"
                />
              </div>
              <div>
                <q-btn
                  color="positive"
                  text-color="white"
                  class="full-width"
                  label="회원가입 하기"
                  @click="handleLogin"
                ></q-btn>
              </div>
            </form>
          </div>
        </div>
      </div>
    </q-page-container>
  </q-layout>
</template>

<script>
export default {};
</script>

<style></style>
