<template>
  <q-btn flat :label="UserName" color="black" icon="account_circle">
    <q-menu :offset="[0, 10]">
      <q-list style="min-width: 100px">
        <q-item clickable @click="click_logout">
          <q-item-section>Logout</q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: 'com-top_menu',
  data: function () {
    return {
      UserName: '',
      router: useRouter(),
    };
  },
  methods: {
    click_logout: function () {
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token');
        this.$router.push('/user/login');
      }
    },
  },
  mounted: function () {
    var vm = this;
    vm.UserName = this.$c.cookie.get('user_name');
  },
};
</script>
