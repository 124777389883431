import { vModelText } from 'vue';
import { createStore } from 'vuex';
import $c from '../assets/libs/common.js';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  plugins: [createPersistedState()],
  state: {
    accesToken: null,
    nickName: '관리자',
    isShow: true,
    modelId: '',
    id: '',
    title: '',
    removeItems: [],
  },
  mutations: {
    addId: function (state, id) {
      return (state.id = id);
    },
    addmodelId: function (state, id) {
      return (state.modelId = id);
    },
    addNickName: function (state, name) {
      return (state.nickName = name);
    },
    addTitle: function (state, title) {
      return (state.title = title);
    },
    checkRemove: function (state, originId) {
      state.removeItems.push(originId);
    },
    i18n(state, i18n) {
      state.i18n[i18n.url] = i18n.value;
    },
  },
  actions: {
    get_user(context, callback) {
      $c.io.get('/api/user/info', {}).then(function (response) {
        var data = response.data;
        if (data['success']) {
          if (data.user) {
            context.state.UID = data.user.UID;
            context.state.CenterId = data.user.CenterId;
            context.state.DisplayName = data.user.DisplayName;
            context.state.IsAdmin = data.user.IsAdmin;
          } else {
            context.state.UID = '';
            context.state.CenterId = 0;
            context.state.DisplayName = '';
          }
        }
        if (callback) {
          callback(data);
        }
      });
    },

    get_i18n(context, options) {
      let url = options.url;
      let callback = options.callback;
      if (context.state.i18n.hasOwnProperty(url)) {
        callback(context.state.i18n[url]);
        return;
      }
      $c.io.get('/api/system/localization/languages/cfg', { url: url }).then(function (response) {
        var data = response.data;
        if (data['success']) {
          context.commit('i18n', { url: url, value: data['i18n'] });
          callback(context.state.i18n[url]);
        }
      });
    },

    get_center_list(context) {
      $c.io.get('/api/setting/centers', {}).then(function (response) {
        var data = response.data;
        if (data['success']) {
          for (let i = 0; i < data.center_list.length; i++) {
            let center = data.center_list[i];
            center.label = center.CenterName;
            center.value = center.CenterId;
            context.state.center_dict[center.CenterId] = center;
          }
          context.state.center_list = data.center_list;
        }
      });
    },
    get_course_group_list(context) {
      $c.io.get('/api/setting/course_groups', {}).then(function (response) {
        var data = response.data;
        if (data['success']) {
          for (let i = 0; i < data.course_group_list.length; i++) {
            let course_group = data.course_group_list[i];
            course_group.label = course_group.Title;
            course_group.value = course_group.CourseGroupId;
            course_group.course_list = [];
            course_group.course_tag_list = [];
          }
          context.state.course_group_list = data.course_group_list;
        }
      });
    },

    get_app_list(context) {
      var vm = this;
      $c.io
        .get('/api/settings/' + 'App', {
          params: {},
        })
        .then(function (response) {
          var data = response.data;
          if (data.success) {
            var row = data.setting_list;
            for (let i = 0; i < row.length; i++) {
              if (row[i].Keyword2 == '공지사항') {
                if (
                  context.state.notice_write_permission.hasOwnProperty(context.state.UID) == false
                ) {
                  row.splice(i, 1);
                  break;
                }
              }
            }
            context.state.app_list = row;
          }
        });
    },
  },
});
